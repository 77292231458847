import { Klass } from '#backend/lib/transforms'
import { District } from './District'

export class City {
  id!: number
  name!: string
  code!: string
  remark!: string
  province_id!: number
  province_name!: string
}

export class CityInfo extends City {
  @Klass(District) districts!: District[]
}
