<template>
  <div>
    <nav class="flex h-16 border-b px-3 items-center bg-abc">
      <div class="text-2xl me-6">
        来了100管理系统
      </div>

      <Nav :preset="navbarPt" v-if="account" v-model="tab">
        <NavItem v-for="site in sites" :value="`site-${site.id}`" :class="{ 'hidden': ![1, 6].includes(site.id) }">
          <i class="far fa-fw me-2">
            <img class="w-60" :src="site.icon_attachment.original_url()">
          </i>
          <span>{{ site.name }}</span>
        </NavItem>

        <NavItem value="subject">
          <i class="far fa-fw fa-books me-2"></i>
          <span>内容</span>
        </NavItem>

        <NavItem value="setting">
          <i class="far fa-fw fa-cogs me-2"></i>
          <span>系统</span>
        </NavItem>
      </Nav>

      <Nav :preset="navbarPt" v-if="account" class="ms-auto">
        <DropdownMenu preset="standard">
          <NavItem value="account_management" as-child class="test">
            <DropdownMenuTrigger>
              <i class="far fa-fw fa-user me-1"></i>
              {{account.name}}
              <i class="fa fa-fw fa-caret-down transition-all duration-300 group-data-[state=open]:rotate-180"></i>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem as-child>
                <a href="#" @click.prevent="signOut">退出</a>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </NavItem>
        </DropdownMenu>
      </Nav>
    </nav>

    <TabsRoot v-model="tab">
      <TabsContent v-for="site in sites" :value="`site-${site.id}`">
        <div class="flex items-center h-14 px-3 bg-muted/5">
          <Nav preset="pill">
            <NavItemsSite v-bind="{ site, allow }" />
          </Nav>
        </div>
      </TabsContent>
      <TabsContent value="subject">
        <div class="flex items-center h-14 px-3 bg-muted/5">
          <Nav preset="pill">
            <NavItemsSubject :subjects="subjects" />
          </Nav>
        </div>
      </TabsContent>
      <TabsContent value="setting">
        <div class="flex items-center h-14 px-3 bg-muted/5">
          <Nav preset="pill">
            <NavItemsSetting />
          </Nav>
        </div>
      </TabsContent>
    </TabsRoot>
  </div>
</template>

<script setup lang="ts">
import { Account, Site, Subject } from "#backend/models"
import * as requests from '#backend/requests'
import { useSessionStore } from "#backend/store/session"
import useRequestList from "$commons/components/useRequestList"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "$ui/dropdown-menu"
import { Nav, NavItem, type NavPresenter } from "$ui/nav"
import { bva } from "$ui/utils"
import _ from "lodash"
import { TabsContent, TabsRoot } from 'radix-vue'
import { computed, onBeforeUnmount, onErrorCaptured, ref, watch } from 'vue'
import { useRoute, useRouter } from "vue-router"
import NavItemsSetting from "./NavItemsSetting.vue"
import NavItemsSite from "./NavItemsSite.vue"
import NavItemsSubject from "./NavItemsSubject.vue"
import { usePageStore } from "$commons/components/page"

const domId = _.uniqueId("navbar")
const router = useRouter()
const session = useSessionStore()
const reqs = useRequestList()
const page = usePageStore()
const route = useRoute()

const account = ref(null as Account | null)
const sites = ref([] as Site[])
const subjects = ref([] as Subject[])

function allow(action: string, resource: any) {
  return account.value?.allow(action, resource) ?? false
}

const tab = ref("")

async function reloadData(isSignIn: boolean) {
  if (isSignIn) {
    const a_account = reqs.raw(session.request(requests.account.Get)).setup().wait()
    const a_sites = reqs.raw(session.request(requests.sites.List, 'global')).setup().wait()
    const a_subjects = reqs.raw(session.request(requests.subjects.List, 'global')).setup().wait()

    await reqs.performAll()

    account.value = a_account.value
    sites.value = a_sites.value
    subjects.value = a_subjects.value
  } else {
    sites.value = []
    subjects.value = []
    account.value = null
  }
}

watch(computed(() => session.isSignIn), async (isSignIn) => {
  await reloadData(isSignIn)
}, { immediate: true })

async function signOut() {
  await reqs.add(requests.SignOutRequest).setup().perform()
  session.clear()
  router.push({ name: "root" })
}

const navbarPt = {
  list: bva("flex gap-4 h-full", {}),
  item: bva("flex items-center px-3 text-lg cursor-pointer data-[state=active]:bg-muted/5", {})
} satisfies NavPresenter
</script>
