import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRoute, useRouter, type RouteLocationRaw } from 'vue-router'

export const usePageStore = defineStore('page', () => {
  const id = ref(0)
  const router = useRouter()
  const route = useRoute()

  function sweepGoto(path: RouteLocationRaw) {
    id.value += 1
    router.push(path as any)
  }

  function reload() {
    id.value += 1
    router.push(route.fullPath)
  }

  return { reload, sweepGoto, id }
})