import { Klass } from '#backend/lib/transforms'
import Pagination from '$commons/lib/Pagination'
import { City } from './City'

export class Region {
  id!: number
  code!: string
  name!: string
  remark!: string | null
  enable_teaching!: boolean
  with_common_bundle!: boolean
  default_school_features!: string[]
  site_id!: number

  @Klass(City) cities: City[] = []
}

export class RegionPage extends Pagination<Region> {
  counts_by_city: Record<string, number> = {}
}
