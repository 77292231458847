import { bva } from '$ui/utils'
import type { TreePresenter } from '$ui/tree'

export const standard = {
  root: bva(`
    w-full px-3 py-3
   `, {}),
  children: bva(`
    ms-4.5 border-l-4 border-border border-dashed
  `, {}),
  item: bva(`
    w-full px-3 py-1.5 rounded-md transition-colors
    hover:bg-accent
    flex items-center
    stroke-2
    *:data-part-toggler:size-4
    *:data-part-toggler:me-2
    *:data-part-toggler:text-muted
    *:data-part-toggler:flex *:data-part-toggler:items-center *:data-part-toggler:justify-center

    data-selected:bg-accent data-selected:text-primary
    data-[state=active]:bg-accent data-[state=active]:text-primary
  `, {})
} satisfies TreePresenter
