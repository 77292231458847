<template>
  <RouterLink :to="to" custom #="n">
    <a :href="n.href" @click.stop="n.navigate" :class="props.class" :data-state="isActive(n) ? 'active' : 'inactive'">
      <slot></slot>
    </a>
  </RouterLink>
</template>

<script setup lang="ts">
import { RouterLink, useRoute, type RouteLocationRaw, type UseLinkReturn } from 'vue-router'
import { computed, type HTMLAttributes, type UnwrapRef } from 'vue'

defineOptions({
  inheritAttrs: false
})

interface Props {
  to: RouteLocationRaw
  class?: HTMLAttributes['class']
  activeBy?: "default" | 'exact' | 'fullpath'
}

const props = withDefaults(defineProps<Props>(), {
  activeBy: 'default'
})

const route = useRoute()

function isActive(n: UnwrapRef<UseLinkReturn>) {
  if (props.activeBy == 'exact') {
    return n.isExactActive
  }

  if (props.activeBy == 'fullpath') {
    return route.fullPath == n.href
  }

  return n.isActive
}
</script>
