import { createApp } from 'vue'
import AppTemplate from './AppTemplate.vue'

import './assets/style.css'

import '$commons/vendor/fontawesome'

import '#legacy/coursewares'

import './vendor/copy'
import './vendor/easymde'
import './vendor/selectize'
import './assets/stylesheets'

const app = createApp(AppTemplate)

import.meta.glob('./initializers/*.ts', { eager: true })
const initializers = import.meta.glob('./initializers/*.ts', { eager: true })
for (const initializer of Object.values(initializers)) {
  (initializer as any).default(app)
}

app.mount('#app')
