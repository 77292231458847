import { Klass, Decimal } from '#backend/lib/transforms'
import Big from 'big.js'

export class Agent {
  id!: number
  name: string | null = null
  phone!: string
  schools_count!: number

  @Decimal() balance!: Big
  @Decimal() point!: Big
  @Decimal() discount_rate!: Big
  @Decimal() rebate_rate!: Big

  role!: 'schoolmen' | 'agent' | 'nobody'
  @Klass(String) roles!: string[]

  teacher_id: number | null = null

  upline_id: number | null = null
  upline_name: string | null = null
  upline_phone: string | null = null

  @Klass(Date) created_at!: Date

  get label_text() {
    return `${this.name} - ${this.phone}`
  }

  get name_with_phone() {
    return `${this.name} - ${this.phone}`
  }
}
