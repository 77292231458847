import * as AlertPresenters from './AlertPresenters'
import * as AlertDialogPresenters from './AlertDialogPresenters'
import * as BadgePresenters from './BadgePresenters'
import * as BreadcrumbPresenters from './BreadcrumbPresenters'
import * as ButtonPresenters from './ButtonPresenters'
import * as ButtonGroupPresenters from './ButtonGroupPresenters'
import * as CardPresenters from './CardPresenters'
import * as CollapsiblePresenters from './CollapsiblePresenters'
import * as ContainerPresenters from './ContainerPresenters'
import * as DetailsPresenters from './DetailsPresenters'
import * as DialogPresenters from './DialogPresenters'
import * as DropdownMenuPresenters from './DropdownMenuPresenters'
import * as NavPresenters from './NavPresenters'
import * as PaginationPresenters from './PaginationPresenters'
import * as PopoverPresenters from './PopoverPresenters'
import * as InputPresenters from './InputPresenters'
import * as SeparatorPresenters from './SeparatorPresenters'
import * as FormPresenters from './FormPresenters'
import * as TablePresenters from './TablePresenters'
import * as TabsPresenters from './TabsPresenters'
import * as TooltipPresenters from './TooltipPresenters'
import * as SelectPresenters from './SelectPresenters'
import * as TreePresenters from './TreePresenters'
import { provideAlertPresenters } from '$commons/ui/alert'
import { provideAlertDialogPresenters } from '$commons/ui/alert-dialog'
import { provideBadgePresenters } from '$commons/ui/badge'
import { provideBreadcrumbPresenters } from '$commons/ui/breadcrumb'
import { provideButtonPresenters } from '$commons/ui/button'
import { provideButtonGroupPresenters } from '$commons/ui/button-group'
import { provideCardPresenters } from '$commons/ui/card'
import { provideCollapsiblePresenters } from '$commons/ui/collapsible'
import { provideContainerPresenters } from '$commons/ui/container'
import { provideDetailsPresenters } from '$commons/ui/details'
import { provideDialogPresenters } from '$commons/ui/dialog'
import { provideDropdownMenuPresenters } from '$commons/ui/dropdown-menu'
import { provideFormPresenters } from '$commons/ui/simple_form'
import { provideInputPresenters } from '$commons/ui/input'
import { provideNavPresenters } from '$commons/ui/nav'
import { providePaginationPresenters } from '$commons/ui/pagination'
import { providePopoverPresenters } from '$commons/ui/popover'
import { provideSelectPresenters } from '$commons/ui/select'
import { provideSeparatorPresenters } from '$commons/ui/separator'
import { provideTablePresenters } from '$commons/ui/table'
import { provideTabsPresenters } from '$commons/ui/tabs'
import { provideTooltipPresenters } from '$commons/ui/tooltip'
import { provideTreePresenters } from '$commons/ui/tree'

export function install() {
  provideAlertPresenters(AlertPresenters)
  provideAlertDialogPresenters(AlertDialogPresenters)
  provideBadgePresenters(BadgePresenters)
  provideBreadcrumbPresenters(BreadcrumbPresenters)
  provideButtonPresenters(ButtonPresenters)
  provideButtonGroupPresenters(ButtonGroupPresenters)
  provideCardPresenters(CardPresenters)
  provideCollapsiblePresenters(CollapsiblePresenters)
  provideContainerPresenters(ContainerPresenters)
  provideDetailsPresenters(DetailsPresenters)
  provideDialogPresenters(DialogPresenters)
  provideDropdownMenuPresenters(DropdownMenuPresenters)
  provideFormPresenters(FormPresenters)
  provideInputPresenters(InputPresenters)
  provideNavPresenters(NavPresenters)
  providePaginationPresenters(PaginationPresenters)
  providePopoverPresenters(PopoverPresenters)
  provideSelectPresenters(SelectPresenters)
  provideSeparatorPresenters(SeparatorPresenters)
  provideTablePresenters(TablePresenters)
  provideTabsPresenters(TabsPresenters)
  provideTooltipPresenters(TooltipPresenters)
  provideTreePresenters(TreePresenters)
}
