import { bva } from '$ui/utils'
import { type AlertPresenter } from '$ui/alert'

export const standard = {
  root: bva(`
    relative w-full rounded-lg border px-4 py-3 text-sm
    [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground [&>svg~*]:pl-7
  `, {
    variant: {
      primary: 'border-primary/50 text-primary dark:border-primary [&>svg]:text-primary',
      secondary: 'border-secondary/50 text-secondary dark:border-secondary [&>svg]:text-secondary',
      destructive: 'border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive',
      constructive: 'border-constructive/50 text-constructive dark:border-constructive [&>svg]:text-constructive',
      muted: 'border-muted/50 text-muted dark:border-muted [&>svg]:text-muted',
      silence: 'border-silence/50 text-silence dark:border-silence [&>svg]:text-silence',
      tint: 'border-tint/50 text-tint dark:border-tint [&>svg]:text-tint',
    }
  }),
  title: bva('mb-2 font-medium leading-none tracking-tight', {}),
  description: bva('text-sm [&_p]:leading-relaxed', {}),
} satisfies AlertPresenter
