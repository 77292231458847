<template>
  <div class="p-3 text-center" :class="{ 'p-1': preset == 'fixed' }">
    © {{ current_year }} 来了100管理系统
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const current_year = computed(() => {
  return dayjs().get('year')
})

const preset = computed(() => {
  return route.meta.preset
})
</script>
