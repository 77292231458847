import { UnprocessableEntityError } from "$ui/simple_form"
import { AxiosError, type AxiosInstance, type AxiosRequestConfig, type AxiosResponse, type Method } from "axios"
import { plainToInstance } from "class-transformer"
import _ from "lodash"
import * as qs from "qs"
import type { Subscribable } from "rxjs"
import URI from 'urijs'
import URITemplate from "urijs/src/URITemplate"
import ErrorAccessDenied from "../lib/ErrorAccessDenied"
import { ErrorsObject } from "../lib/ErrorsObject"
import ErrorUnauthorized from "../lib/ErrorUnauthorized"
import Pagination from "../lib/Pagination"
import QiniuFile from "../lib/QiniuFile"
import { DisposableRequest } from "./DisposableRequest"

export interface RequestContext {
  $axios: AxiosInstance,
}

export abstract class BaseRequest<T> extends DisposableRequest<T> {
  endpoint!: string
  method!: Method | string
  graph: string | null = null
  interpolations = {} as { [ x: string]: any }
  headers = {}
  query = {} as { [ x: string]: any }
  data: any

  ctx!: RequestContext

  setup(ctx: { $axios: any }, callback: ((instance: this) => void) | null = null): this {
    this.ctx = ctx

    if (callback) {
      callback(this)
    }

    return this
  }

  async request(): Promise<T> {
    try {
      const config = this.axiosRequestConfig()
      const { $axios } = this.ctx
      const resp = await $axios.request(config)
      const result = this.processResponse(resp)
      return result
    } catch (e) {
      return await this.processError(e)
    }
  }

  perform(data?: any): Subscribable<T> {
    this.data = data
    return this
  }

  async processError(e: any): Promise<T> {
    if (e instanceof AxiosError && e.response?.status === 403) {
      throw new ErrorAccessDenied()
    } else if (e instanceof AxiosError && e.response?.status === 401) {
      throw new ErrorUnauthorized()
    } else if (e instanceof AxiosError && e.response?.status === 422) {
      const errors = this.responseToObject(e.response, ErrorsObject)
      throw new UnprocessableEntityError(errors)
    } else {
      throw e
    }
  }

  abstract processResponse(response: AxiosResponse): T

  buildUrl() {
    const url = URITemplate(this.endpoint).expand(this.interpolations)
    const uri = new URI(url)
    const queryString = qs.stringify(this.query, { arrayFormat: "brackets" })
    return uri.query(queryString).toString()
  }

  axiosRequestConfig() {
    const config = <AxiosRequestConfig>{
      signal: this.aborter.signal,
      url: this.buildUrl(),
      method: this.method,
      headers: this.headers,
    }

    if (this.graph) {
      config.headers!["X-Resource-Graph"] = this.graph
    }

    if (this.data) {
      const formData = this.data instanceof FormData ? this.data : this.buildFormData(this.data)
      config.data = formData
    }

    return config
  }

  buildFormData(params: any) {
    const formData = new FormData()
    for (const name in params) {
      const value = params[name]
      this.fillFormData(formData, name, value)
    }
    return formData
  }

  fillFormData(form_data: FormData, name: string, value: any) {
    if (_.isArray(value)) {
      for (const [ key, val ] of value.entries()) {
        if (_.isPlainObject(val)) {
          this.fillFormData(form_data, `${name}[${key}]`, val)
        } else {
          this.fillFormData(form_data, `${name}[]`, val)
        }
      }
    } else if (value instanceof QiniuFile) {
      if (!value.key) {
        throw new Error("QiniuFile key is empty")
      }

      form_data.append(name, value.key)
    } else if (_.isPlainObject(value)) {
      for (const attr in value) {
        const val = value[attr]
        this.fillFormData(form_data, `${name}[${attr}]`, val)
      }
    } else {
      if (_.isUndefined(value)) return
      _.isNull(value) ? form_data.append(name, "") : form_data.append(name, value)
    }
  }

  responseToObject<K>(resp: AxiosResponse, klass: new () => K): K {
    return plainToInstance(klass, resp.data)
  }

  responseToArray<K>(resp: AxiosResponse<any, any>, klass: new () => K): K[] {
    return plainToInstance<K, K>(klass, resp.data)
  }

  responseToPage<K>(resp: AxiosResponse<any, any>, klass: new () => K) {
    const pagination = new Pagination<K>()
    pagination.list = this.responseToArray(resp, klass)
    pagination.build(resp)
    return pagination
  }
}
