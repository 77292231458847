import { bva } from '$ui/utils'
import { type BreadcrumbPresenter } from '$ui/breadcrumb'

export const standard = {
  root: bva(``, {}),
  ellipsis: bva(`
    flex h-9 w-9 items-center justify-center
  `, {}),
  item: bva(`
    inline-flex items-center gap-1.5
  `, {}),
  link: bva(`
    transition-colors text-muted hover:text-foreground
  `, {}),
  list: bva(`
    flex flex-wrap items-center gap-1.5 break-words text-sm text-muted sm:gap-2.5
  `, {}),
  page: bva(`
    font-normal text-foreground
  `, {}),
  separator: bva(`
    [&>svg]:size-3.5
  `, {})
} satisfies BreadcrumbPresenter
