import { bva, type VariantsConfig } from '$ui/utils'
import { type BadgePresenter, type BadgePresenterConfig } from '$ui/badge'

const base = `
  inline-flex items-center rounded-sm border font-semibold transition-colors
  focus:outline-hidden focus:ring-2 focus:ring-ring focus:ring-offset-2
`

const variants = {
  shape: {
    rounded: 'rounded-sm',
    pill: 'rounded-full',
  },
  size: {
    sm: 'px-1.5 py-[0.05rem] text-xs',
    default: 'px-2.5 py-0.5 text-xs',
    lg: 'px-3 py-1 text-sm',
  }
} satisfies VariantsConfig<BadgePresenterConfig>

export const standard = {
  root: bva(base, {
    ...variants,
    variant: {
      primary: 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
      secondary: 'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
      destructive: 'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
      constructive: 'border-transparent bg-constructive text-constructive-foreground hover:bg-constructive/80',
      muted: 'border-transparent bg-muted text-muted-foreground hover:bg-muted/80',
      silence: 'border-transparent bg-silence text-silence-foreground hover:bg-silence/80',
      tint: 'border-transparent bg-tint text-tint-foreground hover:bg-tint/80',
    },
  })
} satisfies BadgePresenter

export const light = {
  root: bva(base, {
    ...variants,
    variant: {
      primary: 'border-transparent text-primary bg-primary/10 hover:border-primary',
      secondary: 'border-transparent text-secondary bg-secondary/10 hover:border-secondary',
      destructive: 'border-transparent text-destructive bg-destructive/10 hover:border-destructive',
      constructive: 'border-transparent text-constructive bg-constructive/10 hover:border-constructive',
      muted: 'border-transparent text-muted bg-muted/10 hover:border-muted',
      silence: 'border-transparent text-silence bg-silence/10 hover:border-silence',
      tint: 'border-transparent text-tint bg-tint/10 hover:border-tint',
    },
  })
} satisfies BadgePresenter

export const outline = {
  root: bva(base, {
    ...variants,
    variant: {
      primary: 'text-primary border-primary hover:bg-primary/10',
      destructive: 'text-destructive border-destructive hover:bg-destructive/10',
      tint: 'text-[color:hsl(var(--color-tint))] border-[color:hsl(var(--color-tint))] hover:bg-[color:hsl(var(--color-tint)/0.1)]',
      secondary: 'text-secondary border-secondary hover:bg-secondary/10',
      muted: 'text-muted border-muted hover:bg-muted/10',
    },
  })
} satisfies BadgePresenter

declare module '$ui/badge' {
  interface BadgePresenters {
    light: BadgePresenter
    outline: BadgePresenter
  }
}
