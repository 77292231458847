import { Klass } from '#backend/lib/transforms'
import { School } from './School'
import { Admin } from './Admin'
import { Event } from './Event'
import { EventInfo } from './EventInfo'

export class EventCaseInfo {
  id!: number
  state!: string
  state_text!: string
  event_name!: string
  event_id!: number
  @Klass(School) school!: School
  @Klass(Admin) creator!: Admin
  @Klass(Date) begin_at!: Date
  @Klass(Date) close_at!: Date

  @Klass(EventInfo) event!: EventInfo
}
