<template>
  <div class="flex flex-col h-screen">
    <div class="flex-1" :class="{ 'flex flex-col overflow-hidden': preset == 'fixed' }">
      <SuspenseWrapper :helper="navbar">
        <suspense v-bind="{ ...navbar.events() }">
          <Navbar :key="JSON.stringify([ page.id, navbar.key() ])" />
        </suspense>
      </SuspenseWrapper>

      <div class="p-3 flex flex-col flex-1 overflow-hidden" :class="{
        'select-none grayscale opacity-40 pointer-events-none': view.state == 'pending',
        'pb-0': preset == 'fixed'
      }">
        <SuspenseWrapper :helper="view">
          <router-view v-slot="{ Component }">
            <keep-alive>
              <suspense v-bind="{ ...view.events() }">
                <component :is="Component" :key="JSON.stringify([ page.id, view.key() ])" />
                <template #fallback>
                  <AppLoading />
                </template>
              </suspense>
            </keep-alive>
          </router-view>
        </SuspenseWrapper>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import AppLoading from '$commons/components/web/AppLoading.vue'
import { usePageStore } from '$commons/components/page'
import SuspenseHelper from '$commons/components/SuspenseHelper'
import SuspenseWrapper from '$commons/components/SuspenseWrapper.vue'
import { useRoute } from 'vue-router'
import Footer from "../components/Footer.vue"
import Navbar from "../components/Navbar.vue"
import { computed } from 'vue'

const route = useRoute()
const page = usePageStore()

const navbar = SuspenseHelper.build(() => 'navbar')
const view = SuspenseHelper.build(() => route.fullPath)

const preset = computed(() => route.meta.preset)
</script>
