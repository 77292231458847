import { type Ref } from "vue"
import { createProvideInject, createRelayPresenterConfig } from '../utils'

export interface TreePresenterConfig {
}

export type TreePresenter = {
  root: (config: Partial<TreePresenterConfig>) => string
  children: (config: Partial<TreePresenterConfig>) => string
  item: (config: Partial<TreePresenterConfig>) => string
}

export interface TreePresenters {
  standard: TreePresenter
}

export const {
  relayInjectPresenterConfig: relayTreePresenterConfig
} = createRelayPresenterConfig<TreePresenterConfig>('tree-presenter-config', {
})

export const {
  useInject: useTreePresenter,
  useProvide: provideTreePresenter
} = createProvideInject<Ref<TreePresenter>>('tree-presenter')

export const {
  useInject: useTreePresenters,
  useProvide: provideTreePresenters
} = createProvideInject<TreePresenters>('tree-presenters')
