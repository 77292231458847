import * as t from '#backend/lib/transforms'
import { Site } from './Site'
import { School } from './School'
import { Attachment } from './Attachment'
import { Edition } from './Edition'

export class GroupUploadInfo {
  id!: number
  remark!: string
  import_no!: string
  @t.Boolean() actived!: boolean

  contact_name!: string
  contact_phone!: string
  contact_email!: string
  school_name!: string
  region!: string

  english_edition_name?: string
  match_edition_name?: string
  @t.Klass(Edition) editions: Edition[] = []

  @t.Klass(Attachment) excel_attachment!: Attachment

  @t.Boolean() need_actived!: Boolean
  @t.Boolean() personal_name_required!: Boolean
  site_id!: number
  site!: Site

  province_id!: number
  city_id!: number
  district_id!: number

  school_id?: number
  school?: School

  @t.Number() edition_ids: number[] = []

  @t.Klass(Date) created_at!: Date
}
