import { bva } from '$ui/utils'
import { type CardPresenter } from '$ui/card'

export const standard = {
  root: bva(`rounded-md flex flex-col border bg-card text-card-foreground shadow-xs`, {}),
  header: bva(`
    flex gap-y-1.5 p-6 py-4 border-b
    *:data-part-inner:flex *:data-part-inner:items-center *:data-part-inner:flex-1
    *:data-part-actions:ms-auto *:data-part-actions:flex *:data-part-actions:gap-x-1
  `, {}),
  footer: bva(`flex items-center p-6 py-4`, {}),
  title: bva(`font-semibold leading-none tracking-tight`, {}),
  content: bva(`p-6 py-4 grow`, {}),
  description: bva(`text-sm text-muted`, {}),
  topState: bva(`
    h-1 absolute top-0 w-full bg-current rounded-t-md
    before:absolute before:size-full before:-translate-x-full before:bg-clip-content
    after:absolute after:size-full after:bg-clip-content
  `, {}),
  table: bva(`[&_tr>*]:first:ps-4 [&_tr>*]:last:pe-4`, {}),
} satisfies CardPresenter
