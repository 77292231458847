import { Klass } from '#backend/lib/transforms'
import { Event } from "./Event"
import { EventCase } from './EventCase'
import { EventItem } from './EventItem'
import { Territory } from './Territory'

export class EventInfo extends Event {
  @Klass(EventCase) event_cases: EventCase[] = []
  @Klass(EventItem) event_items: EventItem[] = []
}
