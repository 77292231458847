import { Site, SiteInfo } from "#backend/models"
import { type AxiosResponse } from "axios"
import { BaseRequest } from "$commons/components/BaseRequest"

export const List = class extends BaseRequest<Site[]> {
  method = "GET"
  endpoint = "/api/sites"

  constructor(key: 'global') {
    super()
  }

  processResponse(resp: AxiosResponse) {
    return this.responseToArray(resp, Site)
  }
}

export const Get = class extends BaseRequest<Site> {
  method = "GET"
  endpoint = "/api/sites/{id}"

  processResponse(resp: AxiosResponse) {
    return this.responseToObject(resp, Site)
  }
}

export const InfoGet = class extends BaseRequest<SiteInfo> {
  method = "GET"
  endpoint = "/api/sites/{id}"
  graph = "info"

  processResponse(resp: AxiosResponse) {
    return this.responseToObject(resp, SiteInfo)
  }
}

export const InfoUpdate = class extends BaseRequest<SiteInfo> {
  method = "PATCH"
  endpoint = "/api/sites/{id}"
  graph = "info"

  processResponse(resp: AxiosResponse) {
    return this.responseToObject(resp, SiteInfo)
  }
}

export const InfoList = class extends BaseRequest<SiteInfo[]> {
  method = "GET"
  endpoint = "/api/sites"
  graph = "info"

  processResponse(resp: AxiosResponse) {
    return this.responseToArray(resp, SiteInfo)
  }
}
