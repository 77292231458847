import { Subject } from "#backend/models"
import { BaseRequest } from "$commons/components/BaseRequest"
import { type AxiosResponse } from "axios"

export const List = class extends BaseRequest<Subject[]> {
  method = "GET"
  endpoint = "/api/subjects"

  constructor(key: 'global') {
    super()
  }

  buildUrl(): string {
    this.query.limit = 1000
    return super.buildUrl()
  }

  processResponse(resp: AxiosResponse) {
    return this.responseToArray(resp, Subject)
  }
}

export const Get = class extends BaseRequest<Subject> {
  method = "GET"
  endpoint = "/api/subjects/{id}"

  processResponse(resp: AxiosResponse) {
    return this.responseToObject(resp, Subject)
  }
}

export const Update = class extends BaseRequest<Subject> {
  method = "PATCH"
  endpoint = "/api/subjects/{id}"

  processResponse(resp: AxiosResponse) {
    return this.responseToObject(resp, Subject)
  }
}
