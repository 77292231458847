import { Klass } from '#backend/lib/transforms'
import { City } from './City'

export class ProvinceInfo {
  id!: number
  name!: string
  code!: string
  remark!: string

  @Klass(City) cities!: City[]
}
