import { useSessionStore } from "#backend/store/session"
import { type App } from "vue"

export default function(app: App) {
  const router = app.config.globalProperties.router

  router.beforeEach(async (to: any, from: any, next: any) => {
    const session = useSessionStore(app.config.globalProperties.$pinia)

    if (to.meta.auth !== false && !session.isSignIn) {
      next({ name: "login" })
    } else {
      next()
    }
  })
}
