<template>
  <div class="flex flex-col bg-[color:var(--surface-ground)] min-h-lvh">
    <Card class="max-w-lg w-full mx-auto mt-56 relative">
      <CardContent class="p-9 text-center n">
        <div class="text-6xl text-destructive mb-3"><i class="fa-light fa-circle-xmark"></i></div>
        <h2 class="text-lg">
          未知错误
        </h2>
        <div class="overflow-auto">
          <ul class="inline-block text-left">
            <li v-for="error in errors" :key="error">
              {{ buildErrorMessage(error) }}
            </li>
          </ul>
        </div>
      </CardContent>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { Card, CardContent } from "$ui/card"

const props = defineProps<{
  errors: any[]
}>()

function buildErrorMessage(error: Error) {
  return error?.stack ?? error
}
</script>
