import createCacheRequest from '$commons/components/createCacheRequest'
import { useCookies } from '@vueuse/integrations/useCookies'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import dayjs from 'dayjs'

export const useSessionStore = defineStore('session', () => {
  const cookies = useCookies(['isSignIn'], { autoUpdateDependencies: true })
  const isSignIn = computed({
    get: () => {
      return !!cookies.get('isSignIn')
    },
    set: (value) => {
      if (value) {
        cookies.set('isSignIn', '1', { path: '/', expires: dayjs().add(1, 'year').toDate() })
      } else {
        cookies.remove('isSignIn', { path: '/' })
      }
    }
  })

  const markSignIn = () => {
    isSignIn.value = true
  }

  function clear() {
    isSignIn.value = false
    cleanup()
  }

  const { request, cleanup } = createCacheRequest()

  return { clear, isSignIn, markSignIn, request }
})
