<!--
  SNIP: 33d22c009dfa835af4047fb887798c4d
  FILES:
   - DropdownMenuContent.vue
   - DropdownMenuItem.vue
   - DropdownMenuLabel.vue
   - DropdownMenuSeparator.vue
   - DropdownMenuSub.vue
   - DropdownMenuSubContent.vue
   - DropdownMenuSubTrigger.vue
   - DropdownMenuTrigger.vue
   - types.ts => DropdownMenu-types.ts
   - index.ts => DropdownMenu-index.ts
  CHANGELOG:
   - 2025-03-17 整理代码
-->
<script setup lang="ts">
import { computed, type HTMLAttributes, onActivated, withDefaults } from 'vue'
import { DropdownMenuRoot, type DropdownMenuRootEmits, type DropdownMenuRootProps, useForwardPropsEmits } from 'reka-ui'
import { relayDropdownMenuPresenterConfig, useDropdownMenuPresenter, provideDropdownMenuPresenter, type DropdownMenuPresenter, type DropdownMenuPresenterConfig, useDropdownMenuPresenters } from './types'
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'
import { omitProps } from '../utils'

const presenters = useDropdownMenuPresenters()

interface Props {
  class?: HTMLAttributes['class']
  preset?: keyof typeof presenters | DropdownMenuPresenter
}

const props = withDefaults(defineProps<Props & Partial<DropdownMenuPresenterConfig> & DropdownMenuRootProps>(), {
  preset: 'standard'
})
const presenterConfig = relayDropdownMenuPresenterConfig(props)
const presenter = provideDropdownMenuPresenter(computed(() => {
  return typeof props.preset == 'string' ? presenters[props.preset] : props.preset
}))

const emits = defineEmits<DropdownMenuRootEmits>()

const openRaw = defineModel<boolean>('open')
const open = computed({
  get: () => openRaw.value ?? props.defaultOpen ?? false,
  set: (value) => {
    openRaw.value = value
    emits('update:open', value)
  }
})

onBeforeRouteUpdate(() => {
  open.value = false
})

const forwarded = omitProps(useForwardPropsEmits(computed(() => {
  const { open, defaultOpen, preset, ...delegated } = props
  return delegated
}), emits), 'onUpdate:open')
</script>

<template>
  <DropdownMenuRoot v-bind="forwarded" v-model:open="open">
    <slot />
  </DropdownMenuRoot>
</template>
