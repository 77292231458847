import * as t from '#backend/lib/transforms'
import { Agent } from './Agent'

export class Contract {
  id!: number
  @t.Date() start_date!: Date
  @t.Date() end_date!: Date
  memo!: string
  @t.Number() territory_id!: number
  @t.Klass(Agent) agent!: Agent

  @t.Date() created_at!: Date
  @t.Date() updated_at!: Date
}
