<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { DropdownMenuContent, type DropdownMenuContentEmits, type DropdownMenuContentProps, DropdownMenuPortal, useForwardPropsEmits } from 'reka-ui'
import { cn } from '../utils'
import { relayDropdownMenuPresenterConfig, useDropdownMenuPresenter, type DropdownMenuPresenterConfig } from './types'

interface Props {
  class?: HTMLAttributes['class']
  sideOffset?: DropdownMenuContentProps['sideOffset']
}

const props = withDefaults(defineProps<Props & Partial<DropdownMenuPresenterConfig> & DropdownMenuContentProps>(), {
  sideOffset: 4,
})

const presenterConfig = relayDropdownMenuPresenterConfig(props)
const presenter = useDropdownMenuPresenter()

const emits = defineEmits<DropdownMenuContentEmits>()
const forwarded = useForwardPropsEmits(computed(() => {
  const { class: _, ...delegated } = props
  return delegated
}), emits)
</script>

<template>
  <DropdownMenuPortal>
    <DropdownMenuContent v-bind="forwarded" :class="cn(presenter.content(presenterConfig), props.class)">
      <slot />
    </DropdownMenuContent>
  </DropdownMenuPortal>
</template>
