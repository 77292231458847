import { bva } from '$ui/utils'
import { type DetailsPresenter } from '$ui/details'

export const standard = {
  root: bva('grid grid-cols-12', {}),
  content: bva('leading-6', {}),
  item: bva('col-span-12 flex text-sm mb-2 pb-2 last:pb-0 last:mb-0 last:border-0 border-b border-dashed', {}),
  label: bva('font-medium leading-6 text-muted me-3 min-w-24 text-end', {}),
} satisfies DetailsPresenter

export const table = {
  root: bva('rounded-md border bg-Details text-card-foreground shadow-xs', {}),
  content: bva('overflow-hidden transition-all data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down', {}),
  item: bva('flex flex-col gap-y-1.5 p-6 py-4 border-b', {}),
  label: bva('py-2 px-4', {}),
} satisfies DetailsPresenter

declare module '$ui/details' {
  interface DetailsPresenters {
    table: DetailsPresenter
  }
}
