import { LoginCode } from "#backend/models"
import { type AxiosResponse } from "axios"
import { BaseRequest } from "$commons/components/BaseRequest"

export const SignOutRequest = class extends BaseRequest<void> {
  method = "DELETE"
  endpoint = "/api/sign_out"

  processResponse(resp: AxiosResponse) {
    return this.responseToObject(resp, LoginCode)
  }
}
