import { bva } from '$ui/utils'
import type { TablePresenter } from '$ui/table'

export const standard = {
  root: bva('w-full', {}),
  header: bva('[&_tr]:border-b', {}),
  body: bva('[&_tr:last-child]:border-0', {}),
  caption: bva('mt-4 text-sm text-muted', {}),
  row: bva('border-b transition-colors hover:bg-accent data-[state=selected]:bg-accent', {}),
  head: bva(`
    h-10 px-2 text-left align-middle font-medium text-muted
    data-role-checkbox:pr-0 data-role-checkbox:translate-y-0.5
    data-role-actions:text-end
  `, {}),
  cell: bva(`
    p-2 align-middle
    data-role-checkbox:pr-0 data-role-checkbox:translate-y-0.5
    data-role-actions:space-x-3 data-role-actions:text-end
  `, {}),
  footer: bva('border-t bg-muted-foreground/50 font-medium [&>tr]:last:border-b-0', {}),
  empty: bva(`
    p-4 whitespace-nowrap align-middle text-sm text-foreground
    *:data-part-inner:center-center *:data-part-inner:py-10
  `, {}),
} satisfies TablePresenter
