import { bva, cn } from '$ui/utils'
import type { InputPresenter, InputPresenterConfig } from '$ui/input'

export const standard = {
  checkbox: bva(`
    peer size-4 shrink-0 rounded-sm border border-primary shadow-sm text-primary
    focus:outline-hidden focus:border-ring/50 focus:ring-4 focus:ring-ring/25
    disabled:cursor-not-allowed disabled:bg-silence/10

    relative *:data-part-indicator:absolute *:data-part-indicator:inset-0 *:data-part-indicator:stroke-3
    *:data-part-indicator:flex *:data-part-indicator:items-center *:data-part-indicator:justify-center

    data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground
    group-data-[state=invalid]:border-destructive
  `, {}),

  radio(config: Partial<InputPresenterConfig>) {
    return cn(this.checkbox(config), 'rounded-full')
  },

  input: bva(`
    flex w-full rounded-md justify-between items-center
    border border-input bg-transparent shadow-xs transition-colors
    focus-visible:outline-hidden focus-visible:ring-1 focus-visible:ring-ring focus-visible:border-ring
    data-focus:outline-hidden data-focus:ring-1 data-focus:ring-ring data-focus:border-ring
    disabled:cursor-not-allowed disabled:opacity-50 data-disabled:cursor-not-allowed data-disabled:opacity-50
    placeholder:text-muted data-placeholder:text-muted *:data-placeholder:text-muted
    group-data-[state=invalid]:border-destructive
    *:data-[role=caret]:size-4 *:data-[role=caret]:opacity-50
    file:border-r file:border-input
  `, {
    size: {
      xs: 'text-xs py-1 px-2 file:me-2 file:pe-2',
      sm: 'text-sm py-1 px-2 file:me-2 file:pe-2',
      default: 'text-base py-1 px-3 file:me-3 file:pe-3',
      lg: 'text-lg py-3 px-4 file:me-4 file:pe-4'
    }
  }),
  select(config) { return this.input(config) }
} satisfies InputPresenter
